/* eslint-disable */
import { parse, isAfter, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import toast from "react-hot-toast";

const ITALIAN_TIMEZONE = "Europe/Rome";

// Format date only (dd/MM/yyyy)
const formatItalianDate = (date) => {
  if (!date) return "-";
  try {
    const zonedDate = utcToZonedTime(new Date(date), ITALIAN_TIMEZONE);
    return format(zonedDate, "dd/MM/yyyy");
  } catch (error) {
    console.error("Error formatting date:", error);
    return "-";
  }
};

// Format date with time (dd/MM/yyyy HH:mm)
const formatItalianDateWithTime = (date) => {
  if (!date) return "-";
  try {
    const zonedDate = utcToZonedTime(new Date(date), ITALIAN_TIMEZONE);
    return format(zonedDate, "dd/MM/yyyy HH:mm");
  } catch (error) {
    console.error("Error formatting datetime:", error);
    return "-";
  }
};

// Get zoned date object
const getItalianZonedDate = (date) => {
  if (!date) return null;
  try {
    return utcToZonedTime(new Date(date), ITALIAN_TIMEZONE);
  } catch (error) {
    console.error("Error getting zoned date:", error);
    return null;
  }
};

const validateDateRange = (fromDate, toDate) => {
  // Parse the dates
  const parsedFromDate = parse(fromDate, "yyyy-MM-dd", new Date());
  const parsedToDate = parse(toDate, "yyyy-MM-dd", new Date());

  // Check dates format
  if (
    Number.isNaN(parsedFromDate.getTime()) ||
    Number.isNaN(parsedToDate.getTime())
  ) {
    toast.error(
      "Errore: formato data non valido. La data deve essere in formato YYYY-MM-DD."
    );
    return {
      isValid: false,
      invalidField: Number.isNaN(parsedFromDate.getTime())
        ? "from_date"
        : "to_date",
    };
  }

  // Check if the initial date is subsequent to the final date
  if (isAfter(parsedFromDate, parsedToDate)) {
    toast.error(
      "Errore: la data di inizio deve essere antecedente alla data di fine."
    );
    return {
      isValid: false,
      invalidField: "from_date",
    };
  }

  return { isValid: true };
};

export {
  formatItalianDate,
  formatItalianDateWithTime,
  getItalianZonedDate,
  validateDateRange as default,
};
